<template>
  <div class="check_container">
    <el-row class="check_header">
      <el-col :span="24">{{ name }}</el-col>
    </el-row>
    <Count></Count>
    <div class="check_main">
      <div class="info">
        <div class="cardno">
          就诊号：
          <span>{{ patCardNo }}</span>
        </div>
        <div class="cardno">
          就诊人：
          <span>{{ patName }}</span>
        </div>
      </div>
      <div class="tips">温馨提示：每次打印只能选择一条</div>
      <el-table :data="tableData" v-loading="loading" height="90%" style="width: 100%" ref="multipleTable">
        <el-table-column label="报告时间" prop="crtDate">
          <template slot-scope="scope">
            <span>{{ scope.row.检查时间 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="检查部位" prop="studyId">
          <template slot-scope="scope">
            <span>{{ scope.row.检查部位 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="检查科室">
          <template slot-scope="scope">
            <span>{{ scope.row.申请科室 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="检查类型">
          <template slot-scope="scope">
            <span>{{ scope.row.检查类型 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="auto">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.row)" type="primary" class="btton">打印</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--      <div class="btn">-->
      <!--        &lt;!&ndash; <el-button type="primary" class="checkAll" @click.native="checkAll">{{ msg }}</el-button> &ndash;&gt;-->
      <!--        <el-button type="danger" class="print" :loading="isPrint" @click="getTojpg()">打印</el-button>-->
      <!--      </div>-->
    </div>
    <el-button type="primary" @click.native="home" class="home">首页</el-button>
    <el-button type="primary" @click.native="back" class="cheakBack">返回</el-button>
    <Foo></Foo>
  </div>
</template>
<script>
import Foo from '../footer/index'
import Count from '../count/index'
import { getUserPacsInfo } from '@/service/api'
import { jsonPost, formPost } from '@/baseAPI'
import qs from 'qs'

export default {
  components: {
    Foo,
    Count
  },
  data() {
    return {
      name: '',
      editable: false,
      clearable: false,
      isPrint: false,
      tableData: [],
      multipleSelection: [],
      value: '',
      msg: '全选',
      loading: false,
      user: '',
      patCardNo: '',
      cardNo: '',
      patName: '',
      selection: 'single'
    }
  },
  created() {
    this.name = this.$route.query.name
    this.patCardNo = localStorage.getItem('patCardNo') || this.$route.query.MZH
    this.patName = this.$route.query.patName
    this.value = this.formatTime(0)
    this.getUserPacsInfo()
  },
  methods: {
    select(selection, row) {
      if (selection.length > 1) {
        let del_row = selection.shift()
        this.$refs.multipleTable.toggleRowSelection(del_row, false)
      }
    },
    on_select(val) {
      //点击行选中复选框
      if (selection.length > 1) {
        selection.length = 1
      }
      this.$refs.multipleTable.toggleRowSelection(val)
    },
    async getUserPacsInfo() {
      this.loading = true
      const data = {
        cardNo: this.patCardNo
      }
      const { data: res } = await getUserPacsInfo(qs.stringify(data))
      if (res.code === 0) {
        this.loading = false
        this.patName = res.data[0].姓名
        this.tableData = res.data
      } else {
        this.loading = false
        this.$message({
          message: res.msg,
          type: 'error'
        })
      }
    },
    // 获取打印编号
    async getTojpg() {
      if (this.$refs.multipleTable.selection.length === 0) {
        alert('请选择打印项目')
      } else {
        let _this = this
        this.isPrint = true
        const number = this.$refs.multipleTable.selection[0].检查号

        Promise.all([_this.$api.printPacs(params)]).then(data => {
          if (data[0].code === 0) {
          } else {
            this.isPrint = false
          }
        })
      }
    },
    ajax(url, data) {
      const p = new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open('POST', url, false)
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            if ((xhr.status >= 200 && xhr.status < 300) || xhr.status === 304) {
              resolve(JSON.parse(xhr.response))
            } else {
              reject(new Error('Response error'))
            }
          }
        }
        xhr.send(data)
      })
      return p
    },
    async handleEdit(row) {
      let data = {
        patCardNo: row.检查号,
        imgData: row.报告JPG图像
      }
      this.ajax('http://127.0.0.1:8080/printPacsJPG', qs.stringify(data)).then(res => {})
      this.$message({
        message: '打印成功',
        type: 'success'
      })
      console.log(res)
    },
    // printRep (base64) {
    //   let _this = this
    //   const params = base64
    //   Promise.all([_this.$api.printRep(params)]).then((data) => {
    //     this.isPrint = false
    //   });
    // },
    home() {
      this.$router.push('/home')
    },
    back() {
      this.$router.back()
    },
    selectTime(e) {
      console.log(e)
    },
    padStartConvert(n) {
      n = n.toString()
      return n[1] ? n : '0' + n
    },
    formatTime(num) {
      var now = new Date()
      var nowTime = now.getTime()
      var oneDayTime = 24 * 60 * 60 * 1000
      var ShowTime = nowTime + num * oneDayTime
      var myDate = new Date(ShowTime)
      var y = myDate.getFullYear()
      var m = myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1
      var d = myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate()
      return [y, m, d].map(this.padStartConvert).join('-')
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
      if (val.length === this.tableData.length) {
        this.msg = '全不选'
      } else if (val.length === 0) {
        this.msg = '全选'
      }
    },
    checkAll() {
      this.$refs.multipleTable.toggleAllSelection()
      if (this.$refs.multipleTable.selection.length === 0) {
        this.msg = '全不选'
      } else if (this.$refs.multipleTable.selection.length === this.tableData.length) {
        this.msg = '全选'
      }
    }
  }
}
</script>
<style>
.check_container {
  width: 100%;
  font-size: 44px;
  height: 100%;
}

.check_header {
  background-color: #81ace4;
  height: 100px;
  font-size: 42px;
  font-weight: 700;
  line-height: 100px;
  color: white;
  letter-spacing: 20px;
  padding-left: 30px;
}

.check_footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 30px;
  width: 100%;
  background-color: #81ace4;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 30px;
}

.tips {
  font-size: 38px;
}

.check_container .el-input__inner {
  border: 2px solid #000 !important;
  font-size: 24px !important;
}

.el-picker-panel__shortcut {
  line-height: 60px !important;
  font-size: 30px !important;
  text-align: center !important;
  padding: 0 !important;
}

.el-date-picker__header-label {
  font-size: 30px !important;
  line-height: 36px !important;
}

.el-picker-panel__icon-btn {
  font-size: 30px !important;
}

.el-date-table {
  font-size: 40px !important;
}

.el-date-table td {
  width: 50px !important;
  height: 50px !important;
  font-size: 20px !important;
}

.el-date-table td div {
  height: 50px !important;
}

.el-date-table td span {
  width: 46px !important;
  height: 46px !important;
  line-height: 46px !important;
}

.el-picker-panel {
  position: fixed !important;
  left: 670px !important;
}

.el-date-picker.has-sidebar {
  width: 560px !important;
}

.el-date-picker .el-picker-panel__content {
  width: 95% !important;
}

.el-checkbox__inner {
  border: 2px solid gray !important;
}

.el-month-table,
.el-year-table {
  font-size: 20px !important;
}

.check_main {
  position: relative;
  margin: 0 auto;
  width: 90%;
  height: 50%;
}

.check_main .info {
  text-align: center;
  font-size: 24px;
  color: #409eff;
}

.check_main .info .cardno,
.check_main .info .block {
  display: inline-block;
  padding: 20px 160px;
}

.check_main .el-table {
  margin-top: 20px;
}

.check_main th {
  font-size: 26px !important;
  background-color: #0359c8 !important;
  text-align: center !important;
  color: white !important;
}

.check_main td {
  font-size: 26px !important;
  color: #0359c8 !important;
  text-align: center !important;
  background-clip: padding-box !important;
}

.check_container .el-checkbox__inner {
  width: 30px !important;
  height: 30px !important;
  margin-top: -7px !important;
  margin-left: 20px !important;
}

.el-checkbox__inner::after {
  left: 7px !important;
  top: 4px !important;
}

.el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
  top: 8px !important;
}

.check_main .btn {
  margin-top: 20px;
  text-align: center;
}

.check_main .btn .el-button {
  font-size: 30px !important;
  margin: 0 50px !important;
}

.print {
  width: 220px;
  height: 120px;
  font-size: 30px !important;
}

.cheakBack {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 300px;
  font-size: 26px !important;
}

.home {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 30px;
  font-size: 26px !important;
}
</style>
